import React from 'react'
import { makeStyles, Box, Link, Typography } from '@material-ui/core'
import Logo from './Logo'
import { EULA_URL } from '../constants'

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    color: theme.palette.text.info,
    marginBottom: theme.spacing(4),
    '&:last-of-type': {
      textTransform: 'uppercase',
      marginBottom: 0,
    },
  },
}))

function Disclaimer() {
  const classes = useStyles()
  return (
    <Box p={3} mt={1}>
      <Box mb={2} display="flex" justifyContent="center">
        <Logo />
      </Box>
      <Typography align="center" className={classes.disclaimer}>
        A Zen Heads által a Weboldalon közzétett információk csak tájékoztató
        jellegűek. A Weboldalon közzétett minden információ jóhiszeműen, segítő
        szándékkal került közzétételre, mindazonáltal nem vállalunk semmiféle
        kifejezett vagy hallgatólagos garanciát a Weboldalon található
        információk pontosságára, megfelelőségére, érvényességére,
        megbízhatóságára, rendelkezésre állására vagy teljességére vonatkozóan.
        A Weboldal használatával kapcsolatos teljes Jogi Nyilatkozatunk{' '}
        <Link
          target="_blank"
          href={EULA_URL}
          underline="always"
          color="inherit"
        >
          itt
        </Link>{' '}
        elérhető.
      </Typography>
      <Typography align="center" className={classes.disclaimer}>
        SEMMILYEN KÖRÜLMÉNYEK KÖZÖTT NEM TARTOZUNK FELELŐSSÉGGEL SEMMILYEN
        VESZTESÉGÉRT VAGY KÁRÉRT, AMELY A WEBOLDAL HASZNÁLATÁBÓL VAGY AZ AZON
        KÖZZÉTETT INFORMÁCIÓK DÖNTÉSHOZATALRA VALÓ FELHASZNÁLÁSBÓL ERED. A
        WEBOLDALT, ILLETVE AZ AZON KÖZZÉTETT INFORMÁCIÓT DÖNTÉSHOZATAL CÉLJÁBÓL
        A FELHASZNÁLÓ A SAJÁT FELELŐSSÉGÉRE HASZNÁLJA.
      </Typography>
    </Box>
  )
}

export default Disclaimer
