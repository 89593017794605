import amplitude from 'amplitude-js/amplitude'
import GoogleAnalytics from 'react-ga'

const enableTracking = process.env.NODE_ENV === 'production'
// create and hold amplitudeClient
const amplitudeClient = amplitude.getInstance()

function trackAmplitudeEvent(eventType, eventProperties) {
  const timestamp = Date.now()
  amplitudeClient.logEventWithTimestamp(eventType, eventProperties, timestamp)
}

export const initTracking = () => {
  if (enableTracking) {
    GoogleAnalytics.initialize(process.env.REACT_APP_GA_API_KEY)
    amplitudeClient.init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
      includeUtm: true,
    })
  } else {
    console.warn('Tracking is not enabled in development or test mode')
  }
}

export const trackPageview = () => {
  if (enableTracking)
    GoogleAnalytics.pageview(window.location.pathname + window.location.search)
  else
    console.log('[Tracking] pageview', {
      page: window.location.pathname + window.location.search,
    })
}

export const trackCalculationSuccess = (eventProperties) => {
  if (enableTracking)
    trackAmplitudeEvent('calculation_success', eventProperties)
  else console.log('[Tracking] calculation_success', { eventProperties })
}

export const trackCalculationFailure = (eventProperties) => {
  if (enableTracking) trackAmplitudeEvent('calculation_fail', eventProperties)
  else console.log('[Tracking] calculation_fail', { eventProperties })
}
