export const url =
  'https://whhc4h2zs3.execute-api.eu-central-1.amazonaws.com/production/calculate/'

const headers = {
  'Content-Type': 'application/json',
}

export const options = {
  headers,
}
