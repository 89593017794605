import React from 'react'
import { number, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { colors } from '../constants/theme'

const useStyles = makeStyles((theme) => ({
  link: {
    padding: theme.spacing(2),
  },
}))

export default function Logo({ color, width, height }) {
  const classes = useStyles()
  return (
    <a href="https://zenheads.hu" className={classes.link}>
      <svg
        width={width}
        height={height}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 133 22"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M15.692 12.571L12.553 9.43V6.286l3.14-3.144v3.144L18.83 9.43v3.142l-3.138 3.144v-3.144z"
            fill="#001A2F"
          />
          <path
            d="M0 6.286L6.277 0h6.276l3.14 3.142-3.14 3.144V9.43l3.14 3.142v3.144L9.415 22l-1.58-1.56-3.138-3.143-1.56-1.582L0 12.571V6.286z"
            fill="#58C2DA"
          />
          <path
            d="M26.107 12.571l3.138-3.142V6.286l-3.138-3.144v3.144L22.968 9.43v3.142l3.14 3.144v-3.144z"
            fill="#001A2F"
          />
          <path
            d="M41.8 6.286L35.523 0h-6.278l-3.137 3.142 3.137 3.144V9.43l-3.137 3.142v3.144L32.383 22l1.582-1.56 3.138-3.143 1.558-1.582 3.139-3.144V6.286z"
            fill="#47B4CC"
          />
        </g>
        <path
          d="M56.116 13.205v1.848h-8.568v-1.47L52.924 7.1H47.66V5.253h8.246v1.47l-5.362 6.482h5.572zM65.158 13.233v1.82H57.57v-9.8h7.406v1.82h-5.152V9.2h4.55v1.764h-4.55v2.268h5.334zM75.95 5.253v9.8H74.09l-4.886-5.95v5.95h-2.24v-9.8h1.876l4.872 5.95v-5.95h2.24zM91.222 5.253v9.8h-2.268v-4.018h-4.452v4.018h-2.268v-9.8h2.268v3.864h4.452V5.253h2.268zM101.129 13.233v1.82h-7.588v-9.8h7.406v1.82h-5.152V9.2h4.55v1.764h-4.55v2.268h5.334zM109.387 12.953h-4.55l-.868 2.1h-2.324l4.368-9.8h2.24l4.382 9.8h-2.38l-.868-2.1zm-.714-1.722l-1.554-3.752-1.554 3.752h3.108zM113.652 5.253h4.452c1.064 0 2.002.205 2.814.616.822.401 1.456.97 1.904 1.708.458.737.686 1.596.686 2.576s-.228 1.838-.686 2.576c-.448.737-1.082 1.311-1.904 1.722-.812.401-1.75.602-2.814.602h-4.452v-9.8zm4.34 7.938c.98 0 1.76-.27 2.338-.812.588-.55.882-1.293.882-2.226 0-.934-.294-1.67-.882-2.212-.578-.55-1.358-.826-2.338-.826h-2.072v6.076h2.072zM128.439 15.22a8.259 8.259 0 01-2.254-.307c-.719-.215-1.298-.49-1.736-.826l.77-1.708c.42.308.919.555 1.498.742a5.62 5.62 0 001.736.28c.644 0 1.12-.094 1.428-.28.308-.196.462-.453.462-.77a.705.705 0 00-.28-.574 1.93 1.93 0 00-.7-.378c-.28-.094-.663-.196-1.148-.308a15.926 15.926 0 01-1.834-.532 3.03 3.03 0 01-1.232-.854c-.336-.392-.504-.915-.504-1.568 0-.57.154-1.083.462-1.54.308-.467.77-.836 1.386-1.106.625-.27 1.386-.406 2.282-.406a7.54 7.54 0 011.834.224c.597.15 1.12.364 1.568.644l-.7 1.722c-.906-.514-1.811-.77-2.716-.77-.635 0-1.106.102-1.414.308-.299.205-.448.476-.448.812 0 .336.172.588.518.756.354.158.891.317 1.61.476.746.177 1.358.354 1.834.532.476.177.882.457 1.218.84.345.382.518.9.518 1.554 0 .56-.159 1.073-.476 1.54-.308.457-.775.821-1.4 1.092-.626.27-1.386.406-2.282.406z"
          fill={color}
        />
        <defs>
          <clipPath id="clip0">
            <path d="M0 0h41.8v22H0V0z" fill="#fff" />
          </clipPath>
        </defs>
      </svg>
    </a>
  )
}

Logo.defaultProps = { width: 133, height: 22, color: colors.dark }
Logo.propTypes = { width: number, height: number, color: string }
