import React from 'react'
import { number } from 'prop-types'
import {
  Box,
  makeStyles,
  Tooltip,
  withStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { animated, useSpring } from 'react-spring'
import { colors } from '../constants/theme'
import stripesSrc from '../assets/img/stripes.svg'
import { sizes } from '../constants/theme'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 13,
  },
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
  container: {
    width: 76,
    borderRadius: 4,
    overflow: 'hidden',
    background: `url(${stripesSrc}) repeat`,
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: 52,
      cursor: 'pointer',
    },
  },
  debt: {
    background: `linear-gradient(to bottom, rgba(255,255,255,0.7) 0%, transparent 100%)`,
  },
  interest: {
    backgroundColor: colors.interest,
    opacity: 0.9,
  },
  accrued: {
    backgroundColor: colors.accrued,
    opacity: 0.9,
  },
  outerContainer: {
    height: sizes.defaultChart,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
}))

const numberWithSpaces = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

const renderTooltipTitle = (text, value) => (
  <div>
    <div>{`${text}: `}</div>
    <div>
      <b>{`${numberWithSpaces(Math.round(value))} Ft`}</b>
    </div>
  </div>
)

const AnimatedTooltip = animated(LightTooltip)
const AnimatedBox = animated(Box)

function Bar({ debt, interest, accrued, innerHeight }) {
  const classes = useStyles({ innerHeight })

  const total = debt + interest + accrued

  const accruedChart = React.useMemo(
    () => Math.round((accrued / total) * 100),
    [accrued, total]
  )
  const interestChart = React.useMemo(
    () => Math.round((interest / total) * 100),
    [interest, total]
  )
  const debtChart = React.useMemo(() => Math.round((debt / total) * 100), [
    debt,
    total,
  ])

  const accruedTitle = React.useMemo(
    () => renderTooltipTitle('Moratórium miatt felhalmozott kamat', accrued),
    [accrued]
  )
  const interestTitle = React.useMemo(
    () => renderTooltipTitle('Kamatfizetés', interest),
    [interest]
  )
  const debtTitle = React.useMemo(
    () => renderTooltipTitle('Tőketörlesztés', debt),
    [debt]
  )

  const { flexDebt, flexInterest, flexAccrued } = useSpring({
    flexDebt: debtChart,
    flexInterest: interestChart,
    flexAccrued: accruedChart,
  })

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const grow = useSpring({
    from: { height: 0 },
    height: matches ? innerHeight : sizes.defaultChart,
    delay: 300,
    friction: 35,
  })

  return (
    <Box className={classes.outerContainer}>
      <AnimatedBox
        display="flex"
        flexDirection="column"
        className={classes.container}
        style={grow}
      >
        <AnimatedTooltip
          title={accruedTitle}
          style={{
            flexBasis: flexAccrued.interpolate((val) => `${Math.ceil(val)}%`),
          }}
          className={classes.accrued}
          placement="right"
        >
          <Box />
        </AnimatedTooltip>
        <AnimatedTooltip
          title={interestTitle}
          style={{
            flexBasis: flexInterest.interpolate((val) => `${Math.ceil(val)}%`),
          }}
          className={classes.interest}
          placement="right"
        >
          <Box />
        </AnimatedTooltip>
        <AnimatedTooltip
          title={debtTitle}
          style={{
            flexBasis: flexDebt.interpolate((val) => `${Math.ceil(val)}%`),
          }}
          className={classes.debt}
          placement="right"
        >
          <Box />
        </AnimatedTooltip>
      </AnimatedBox>
    </Box>
  )
}

export default Bar

Bar.propTypes = {
  debt: number.isRequired,
  interest: number.isRequired,
  accrued: number,
  innerHeight: number,
}

Bar.defaultProps = {
  accrued: 0,
  innerHeight: sizes.defaultChart,
}
