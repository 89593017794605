import React from 'react'
import { number, string } from 'prop-types'
import { colors } from '../constants/theme'
export default function Icon({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 10H4V17H7V10Z" fill={color} />
      <path d="M13.5 10H10.5V17H13.5V10Z" fill={color} />
      <path d="M22 19H2V22H22V19Z" fill={color} />
      <path d="M20 10H17V17H20V10Z" fill={color} />
      <path d="M12 1L2 6V8H22V6L12 1Z" fill={color} />
    </svg>
  )
}

Icon.defaultProps = { size: 24, color: colors.white }
Icon.propTypes = { size: number, color: string }
