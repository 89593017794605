import React from 'react'
import { makeStyles, Paper, Typography } from '@material-ui/core'
import { func } from 'prop-types'
import CalculatorForm from './CalculatorForm'
import { z } from '../constants/theme'

const useStyles = makeStyles((theme) => ({
  settings: {
    padding: theme.spacing(8),
    background: theme.palette.background.default,
    zIndex: z.settings,
    boxShadow: '9px 9px 16px rgba(163,177,198,0.3)',
    width: 340,
    margin: '0  auto',
    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
  },
  title: {
    marginBottom: theme.spacing(6),
  },
}))

function Settings({ onSuccess }) {
  const classes = useStyles()
  return (
    <Paper className={classes.settings} elevation={0}>
      <Typography
        variant="h2"
        color="primary"
        align="center"
        className={classes.title}
      >
        Jelenlegi hitelem
      </Typography>
      <CalculatorForm onSuccess={onSuccess} />
    </Paper>
  )
}

export default Settings

Settings.propTypes = { onSuccess: func.isRequired }
