import { createMuiTheme } from '@material-ui/core/styles'

export const colors = {
  white: '#fefefe',
  dark: '#272755',
  green: '#5CEBC9',
  blue: '#16A6C7',
  inputBg: '#F6F6FC',
  inputBorder: '#9796FF',
  error: '#f00',
  defaultBg: '#FEFEFE',
  disclaimer: '#9292AC',
  inputLabel: '#8686A8',
  lightBlue: '#4FCBEB',
  interest: '#A465F5',
  accrued: '#F1AB42',
  disabledBtn: '#EAEBEF',
  hoveredBtn: '#37376A',
}

export const sizes = {
  header: 66,
  defaultChart: 290,
}

export const z = {
  settings: 100,
}

export default createMuiTheme({
  props: {
    MuiButtonBase: { disableRipple: true },
    MuiInput: { disableUnderline: true },
    MuiFilledInput: { disableUnderline: true },
  },
  spacing: 5,
  palette: {
    primary: {
      main: colors.dark,
    },
    secondary: {
      main: colors.white,
      light: colors.inputBg,
    },
    background: {
      main: colors.white,
      secondary: colors.blue,
      default: colors.defaultBg,
    },
    text: {
      primary: colors.white,
      secondary: colors.dark,
      info: colors.disclaimer,
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  overrides: {
    MuiTypography: {
      root: {
        position: 'relative',
      },
      h2: {
        fontWeight: 'bold',
        fontSize: 20,
        lineHeight: 1.3,
      },
      body1: {
        color: colors.dark,
        fontSize: 14,
      },
      subtitle1: {
        fontSize: 14,
        padding: '5px 10px',
        backgroundColor: colors.lightBlue,
      },
      subtitle2: {
        fontWeight: 'normal',
        fontSize: 16,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'initial',
      },
      containedPrimary: {
        '&$disabled': {
          background: colors.disabledBtn,
          color: colors.inputLabel,
        },
        '&:hover': {
          backgroundColor: colors.hoveredBtn,
        },
      },
    },
    MuiInputBase: {
      root: {
        color: colors.dark,
        '.animated-adornment &': {
          '&$focused .MuiInputAdornment-positionEnd': {
            marginTop: 16,
          },
        },
      },
      formControl: {
        fontSize: 16,
        lineHeight: '20px',
        border: 'none',
        borderRadius: 5,
        '&$error': {
          borderColor: colors.error,
        },
      },
      input: {
        padding: '20px 10px',
        height: 20,
        fontSize: 14,
        fontWeight: 600,
        '.animated-adornment &': {
          '&:valid + .MuiInputAdornment-positionEnd': {
            marginTop: 16,
          },
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: colors.inputBg,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',
        '&$focused,&:hover': {
          backgroundColor: colors.inputBg,
        },
        '&$error': {
          borderColor: colors.error,
        },
      },
    },
    MuiInputAdornment: {
      root: {
        fontSize: 14,
        fontWeight: 500,
      },
      positionEnd: {
        '.animated-adornment &': {
          transition: 'margin-top 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.inputLabel,
        '&$focused': { color: colors.inputLabel },
      },
    },
    MuiInputLabel: {
      formControl: {
        color: colors.inputLabel,
        fontSize: 13,
        lineHeight: '16px',
        fontWeight: 'normal',
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiFormHelperText: {
      root: {
        lineHeight: 1.1,
        maxHeight: 0,
        transition: 'max-height 0.3s ease-out',
        '&$error': {
          marginLeft: 0,
          marginRight: 0,
          maxHeight: 20,
        },
      },
    },
    MuiPaper: {
      root: {
        position: 'relative',
      },
      rounded: {
        borderRadius: 10,
      },
    },
    MuiPickersDay: {
      day: {
        color: '#000',
      },
    },
  },
})
