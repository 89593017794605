import React from 'react'
import { makeStyles, Paper, Box, Typography, Grid } from '@material-ui/core'
import { shape, number, string } from 'prop-types'
import cx from 'classnames'
import Bar from './Bar'
import debtSrc from '../assets/img/debt.svg'
import interestSrc from '../assets/img/interest.svg'
import accruedSrc from '../assets/img/accrued.svg'
import AnimatedNumber from './AnimatedNumber'
import { sizes } from '../constants/theme'
import { animated, useSpring } from 'react-spring'

const useStyles = makeStyles((theme) => ({
  results: {
    background: theme.palette.background.secondary,
    padding: `${theme.spacing(9)}px 0`,
    '&>div': {
      overflow: 'hidden',
    },
    '&:before': {
      content: '""',
      width: '100vw',
      background: theme.palette.background.secondary,
      height: 'calc(100% + 70px)',
      position: 'absolute',
      left: -10,
      bottom: 0,
      borderRadius: 10,
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
      background: 'initial',
      '&:before': {
        content: 'initial',
      },
    },
  },
  endResult: {
    marginTop: theme.spacing(2),
    fontSize: 30,
    fontWeight: 'bold',
    lineHeight: 1,
  },
  info: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1,
    marginTop: 2,
  },
  labeledInfo: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      fontSize: 14,
    },
  },
  debt: {
    '&:before': {
      background: `url(${debtSrc})`,
      transform: 'rotate(90deg)',
      content: '""',
      width: 10,
      height: 10,
      position: 'absolute',
      left: -20,
      top: 5.5,
      margin: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      '&:before': {
        left: 0,
      },
    },
  },
  interest: {
    '&:before': {
      background: `url(${interestSrc})`,
      content: '""',
      width: 10,
      height: 10,
      position: 'absolute',
      left: -20,
      top: 5.5,
      margin: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      '&:before': {
        left: 0,
      },
    },
  },
  accrued: {
    '&:before': {
      background: `url(${accruedSrc})`,
      content: '""',
      width: 10,
      height: 10,
      position: 'absolute',
      left: -20,
      top: 5.5,
      margin: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      '&:before': {
        left: 0,
      },
    },
  },
  container: {
    '& > *': {
      position: 'relative',
    },
    [theme.breakpoints.up('md')]: {
      margin: '64px 0 44px',
    },
  },
  details: {
    marginLeft: theme.spacing(8),
  },
  withContainer: {
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  with: {
    padding: `${theme.spacing(4)}px 0 ${theme.spacing(6)}px ${theme.spacing(
      8
    )}px`,
    marginRight: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      borderBottom: '1px solid rgba(255,255,255,0.3)',
      borderLeft: '1px solid rgba(255,255,255,0.3)',
    },
  },
  withoutContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  without: {
    padding: `${theme.spacing(4)}px ${theme.spacing(6)}px ${theme.spacing(
      5
    )}px 0px`,
    marginLeft: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.spacing(8)}px)`,
      paddingRight: theme.spacing(8),
      borderBottom: '1px solid rgba(255,255,255,0.3)',
    },
  },
  chartSection: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(4),
    },
  },
  extraCost: {
    borderTop: '1px solid rgba(255,255,255,0.3)',
    marginBottom: theme.spacing(6),
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      margin: 0,
      paddingLeft: theme.spacing(8),
      paddingTop: theme.spacing(4),
      borderTop: 'initial',
      order: 3,
    },
  },
  extraTime: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      margin: 0,
      paddingLeft: theme.spacing(8),
      paddingTop: theme.spacing(4),
      order: 4,
    },
  },
  title: {
    marginLeft: theme.spacing(8),
    marginBottom: 'auto',
    display: 'inline-block',
  },
}))

const AnimatedPaper = animated(Paper)

function Results({ results }) {
  const classes = useStyles()
  const fade = useSpring({ from: { opacity: 0 }, opacity: 1, delay: 100 })

  const withTotal = Math.round(
    results.new.principal + results.new.interest + results.new.interest_accrued
  )
  const withoutTotal = Math.round(
    results.original.principal + results.original.interest
  )
  const accruedPart = Math.round(
    (results.new.interest_accrued / withTotal) * 100
  )
  const withoutHeight = sizes.defaultChart * (1 - accruedPart / 100)

  return (
    <AnimatedPaper className={classes.results} elevation={0} style={fade}>
      {/* DIV: need for animations */}
      <div>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={6} className={classes.withContainer}>
            <Typography
              color="secondary"
              align="left"
              variant="subtitle1"
              className={classes.title}
            >
              Moratóriummal
            </Typography>
            <Box className={cx(classes.with, classes.chartSection)}>
              <Bar
                debt={results.new.principal}
                interest={results.new.interest}
                accrued={results.new.interest_accrued}
              />
              <Box className={classes.details}>
                <Box mb={3}>
                  <Typography color="secondary">Hátralévő futamidő:</Typography>
                  <Typography color="secondary" className={classes.info}>
                    <AnimatedNumber val={results.new.remaining_term} /> hónap
                  </Typography>
                </Box>
                <Box mb={3}>
                  <Typography color="secondary">Teljes kifizetés:</Typography>
                  <Typography color="secondary" className={classes.info}>
                    <AnimatedNumber val={withTotal} /> Ft
                  </Typography>
                </Box>
                <Box mb={3}>
                  <Typography color="secondary" className={classes.accrued}>
                    Moratórium miatt felhalmozott kamat:
                  </Typography>
                  <Typography
                    color="secondary"
                    className={cx(classes.info, classes.labeledInfo)}
                  >
                    <AnimatedNumber
                      val={Math.round(results.new.interest_accrued)}
                    />{' '}
                    Ft
                  </Typography>
                </Box>
                <Box mb={3}>
                  <Typography color="secondary" className={classes.interest}>
                    Kamatfizetés:
                  </Typography>
                  <Typography
                    color="secondary"
                    className={cx(classes.info, classes.labeledInfo)}
                  >
                    <AnimatedNumber val={Math.round(results.new.interest)} /> Ft
                  </Typography>
                </Box>
                <Box>
                  <Typography color="secondary" className={classes.debt}>
                    Tőketörlesztés:
                  </Typography>
                  <Typography
                    color="secondary"
                    className={cx(classes.info, classes.labeledInfo)}
                  >
                    <AnimatedNumber val={Math.round(results.new.principal)} />{' '}
                    Ft
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className={classes.withoutContainer}>
            <Typography
              color="secondary"
              align="left"
              variant="subtitle1"
              className={classes.title}
            >
              Moratórium nélkül
            </Typography>
            <Box className={cx(classes.without, classes.chartSection)}>
              <Bar
                debt={results.original.principal}
                interest={results.original.interest}
                innerHeight={withoutHeight}
              />
              <Box className={classes.details}>
                <Box mb={3}>
                  <Typography color="secondary">Hátralévő futamidő:</Typography>
                  <Typography color="secondary" className={classes.info}>
                    <AnimatedNumber val={results.original.remaining_term} />{' '}
                    hónap
                  </Typography>
                </Box>
                <Box mb={3}>
                  <Typography color="secondary">Teljes kifizetés:</Typography>
                  <Typography color="secondary" className={classes.info}>
                    <AnimatedNumber val={withoutTotal} /> Ft
                  </Typography>
                </Box>
                <Box mb={3}>
                  <Typography color="secondary" className={classes.interest}>
                    Kamatfizetés:
                  </Typography>
                  <Typography
                    color="secondary"
                    className={cx(classes.info, classes.labeledInfo)}
                  >
                    <AnimatedNumber
                      val={Math.round(results.original.interest)}
                    />{' '}
                    Ft
                  </Typography>
                </Box>
                <Box>
                  <Typography color="secondary" className={classes.debt}>
                    Tőketörlesztés:
                  </Typography>
                  <Typography
                    color="secondary"
                    className={cx(classes.info, classes.labeledInfo)}
                  >
                    <AnimatedNumber
                      val={Math.round(results.original.principal)}
                    />{' '}
                    Ft
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className={classes.extraCost}>
            <Box>
              <Typography color="secondary" variant="subtitle2">
                Ennyivel fizetsz többet:
              </Typography>
              <Typography color="secondary" className={classes.endResult}>
                <AnimatedNumber val={withTotal - withoutTotal} /> Ft
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className={classes.extraTime}>
            <Box>
              <Typography color="secondary" variant="subtitle2">
                Ennyivel nő meg a futamidőd:
              </Typography>
              <Typography color="secondary" className={classes.endResult}>
                <AnimatedNumber
                  val={
                    results.new.remaining_term - results.original.remaining_term
                  }
                />{' '}
                hónap
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    </AnimatedPaper>
  )
}

export default Results

Results.propTypes = {
  results: shape({
    original: shape({
      principal: number.isRequired,
      interest: number.isRequired,
      interest_accrued: number.isRequired,
      last_payment_date: string.isRequired,
      remaining_term: number.isRequired,
    }).isRequired,
    new: shape({
      principal: number.isRequired,
      interest: number.isRequired,
      interest_accrued: number.isRequired,
      last_payment_date: string.isRequired,
      remaining_term: number.isRequired,
    }).isRequired,
  }).isRequired,
}
