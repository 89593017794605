export default function handleAPIErrors(err) {
  try {
    if (err.isAxiosError) {
      return err.response.data.nonFieldErrors
    }
  } catch (e) {
    // any unexpected error comes
  }
  return 'Rendszerhiba. Próbáld meg később.'
}
