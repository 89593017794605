import React from 'react'
import { func } from 'prop-types'
import cx from 'classnames'
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Form } from 'react-final-form'
import { KeyboardDatePicker, makeValidate } from 'mui-rff'
import { FORM_ERROR } from 'final-form'
import * as Yup from 'yup'
import { format } from 'date-fns'
import axios from 'axios'
import NumberTextField from './NumberTextField'
import { url, options } from '../constants/config'
import {
  trackCalculationSuccess,
  trackCalculationFailure,
} from '../utils/tracking'
import handleAPIErrors from '../utils/errors'

const schema = Yup.object().shape({
  debt: Yup.number()
    .nullable()
    .typeError('Csak szám lehet')
    .positive('Csak pozitív lehet')
    .required('Kötelező'),
  interest: Yup.number()
    .nullable()
    .typeError('Csak szám lehet')
    .positive('Csak pozitív lehet')
    .required('Kötelező'),
  repayment: Yup.number()
    .nullable()
    .typeError('Csak szám lehet')
    .positive('Csak pozitív lehet')
    .required('Kötelező'),
  lastPayment: Yup.date()
    .nullable()
    .typeError('Nem valós dátum')
    .max(new Date(), 'A dátum nem lehet a jövőben')
    .required('Kötelező'),
})
const validate = makeValidate(schema)

const useStyles = makeStyles((theme) => ({
  interest: {
    width: 106,
  },
  repayment: {
    width: 144,
  },
  progress: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  submit: {
    minHeight: 54,
  },
  error: {
    marginBottom: theme.spacing(2),
  },
}))

const initialValues = {
  debt: null,
  interest: null,
  repayment: null,
  lastPayment: null,
}

export default function CalculatorForm({ onSuccess }) {
  const classes = useStyles()

  const handleFormSubmit = async (values) => {
    const errors = {}
    try {
      const { data } = await axios.post(
        url,
        JSON.stringify({
          current_balance: parseInt(values.debt),
          current_rate: parseFloat(values.interest / 100),
          last_repayment_date: format(values.lastPayment, 'yyyy-MM-dd'),
          last_payment_amount: parseInt(values.repayment),
        }),
        options
      )
      onSuccess(data)
      trackCalculationSuccess({ request: values, response: data })
    } catch (err) {
      const errorMsg = handleAPIErrors(err)
      trackCalculationFailure({ request: values, error: errorMsg })
      errors[FORM_ERROR] = errorMsg
    }

    return errors
  }

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validate={validate}
    >
      {({ handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <Box mb={3}>
            <NumberTextField
              name="debt"
              variant="filled"
              type="text"
              label="Jelenlegi tőketartozás"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" disableTypography>
                    Ft
                  </InputAdornment>
                ),
              }}
              className="animated-adornment"
              helperText=" "
              required
            />
          </Box>
          <Box mb={3}>
            <Box display="flex" justifyContent="space-between">
              <NumberTextField
                name="interest"
                label="Kamat"
                className={cx(classes.interest, 'animated-adornment')}
                variant="filled"
                type="text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" disableTypography>
                      %
                    </InputAdornment>
                  ),
                }}
                helperText=" "
                required
              />
              <NumberTextField
                name="repayment"
                label="Törlesztőrészlet"
                className={cx(classes.repayment, 'animated-adornment')}
                variant="filled"
                type="text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      disableTypography
                      component="span"
                    >
                      Ft
                    </InputAdornment>
                  ),
                }}
                helperText=" "
                required
              />
            </Box>
          </Box>
          <Box mb={6}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              name="lastPayment"
              label="Utolsó levont törlesztés"
              inputVariant="filled"
              disableFuture
              format="yyyy. MM. dd."
              variant="inline"
              helperText=" "
              // InputAdornmentProps
              KeyboardButtonProps={{ size: 'small' }}
            />
          </Box>
          {submitError && !dirtySinceLastSubmit && (
            <Typography color="error" className={classes.error}>
              {submitError}
            </Typography>
          )}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            className={classes.submit}
          >
            {submitting ? (
              <CircularProgress
                size={22}
                color="secondary"
                className={classes.progress}
              />
            ) : (
              'Moratórium kiszámolása'
            )}
          </Button>
        </form>
      )}
    </Form>
  )
}

CalculatorForm.propTypes = { onSuccess: func.isRequired }
