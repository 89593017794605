import React from 'react'
import { number } from 'prop-types'
import { animated, useSpring } from 'react-spring'

function AnimatedNumbers({ val }) {
  const spring = useSpring({
    from: { val: 0 },
    val,
  })

  const numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  return (
    <animated.span>
      {spring.val.interpolate((val) => numberWithSpaces(Math.ceil(val)))}
    </animated.span>
  )
}

export default AnimatedNumbers

AnimatedNumbers.propTypes = {
  val: number.isRequired,
}
