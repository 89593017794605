import React from 'react'
import { func, string, shape } from 'prop-types'
import { TextField } from 'mui-rff'
import NumberFormat from 'react-number-format'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator=" "
      decimalSeparator=","
      isNumericString
      inputMode="decimal"
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: func.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
}

export default function NumberTextField(props) {
  return (
    <TextField
      {...props}
      InputProps={{
        ...props.InputProps,
        inputComponent: NumberFormatCustom,
      }}
    />
  )
}

NumberTextField.propTypes = {
  InputProps: shape({}).isRequired,
}
