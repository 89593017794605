import React from 'react'
import { makeStyles, AppBar, Toolbar, Typography } from '@material-ui/core'
import Icon from './Icon'
import { sizes } from '../constants/theme'

const useStyles = makeStyles((theme) => ({
  header: {
    justifyContent: 'center',
    height: sizes.header,
    padding: '0 23px',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'left',
      paddingTop: 25,
    },
  },
  title: {
    fontSize: 20,
    lineHeight: 1.3,
    marginLeft: theme.spacing(2),
    '& > span': {
      fontWeight: 'bold',
    },
  },
}))

function Header() {
  const classes = useStyles()
  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar className={classes.header}>
        <Icon />{' '}
        <Typography
          color="primary"
          variant="h1"
          pl={1}
          className={classes.title}
        >
          <span>Moratórium</span>Kalkulátor
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default Header
