import React from 'react'
import { makeStyles, Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  description: {
    padding: `${theme.spacing(10)}px ${theme.spacing(3)}px`,
    background: theme.palette.background.secondary,
    '& > :first-child': {
      marginBottom: theme.spacing(4),
    },
    '&:before': {
      content: '""',
      width: '100vw',
      background: theme.palette.background.secondary,
      height: 'calc(100% + 70px)',
      position: 'absolute',
      left: -10,
      top: 0,
      borderRadius: 10,
    },
    [theme.breakpoints.up('md')]: {
      background: 'initial',
      padding: `${theme.spacing(13)}px ${theme.spacing(6)}px`,
      borderRadius: '0 10px 10px 0',
      width: 419,
      '&:before': {
        content: 'initial',
      },
    },
  },
}))

function Description() {
  const classes = useStyles()
  return (
    <Paper className={classes.description} elevation={0}>
      <Typography color="secondary" align="center" variant="h2">
        Add meg hiteled adatait!
      </Typography>
      <Typography color="secondary" align="center">
        A hitelmoratórium kalkulátor segítségével kiszámolhatod, hogy meglévő,
        2020. március 19 előtt folyósított jelzáloghiteledre milyen hatással
        lesz, ha élsz a szüneteltetés lehetőségével.
      </Typography>
    </Paper>
  )
}

export default Description
