import React from 'react'
import cx from 'classnames'
import {
  Box,
  Container,
  Grid,
  useTheme,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core'
import Header from './Header'
import Description from './Description'
import Settings from './Settings'
import Results from './Results'
import CookieDisclaimer from './CookieDisclaimer'
import Disclaimer from './Disclaimer'
import intersectSrc from '../assets/img/intersect.svg'
import patternSrc from '../assets/img/pattern.svg'
import { sizes } from '../constants/theme'
import { trackPageview, initTracking } from '../utils/tracking'
import { animated, useSpring } from 'react-spring'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    background: `
    linear-gradient(to bottom, transparent ${sizes.header + 20}px, #f6f6fB ${
      sizes.header + 20
    }px),
    url(${patternSrc}) repeat,
    linear-gradient(to bottom, #4FCBEB ${sizes.header + 20}px, transparent ${
      sizes.header + 20
    }px)
    `,
    [theme.breakpoints.up('md')]: {
      background: `
      linear-gradient(to bottom, transparent 55vh, #f6f6fB 55vh),
      url(${intersectSrc}) no-repeat 0% calc(55vh - 222px) / 100% 223px,
      url(${patternSrc}) repeat,
      linear-gradient(to bottom, #4FCBEB 55vh, transparent 55vh)
      `,
    },
  },
  outerContainer: {
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  mainContainer: {
    [theme.breakpoints.up('md')]: {
      minHeight: 558,
      marginTop: theme.spacing(5),
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  settingsContainer: {
    transition: 'flex-basis ease-in-out 300ms',
    [theme.breakpoints.up('md')]: {
      order: 1,
      justifyContent: 'flex-end',
      '&.has-results': {
        flexBasis: 300,
      },
    },
  },
  disclaimer: {
    maxWidth: 790,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  combinedContainer: {
    transition: 'flex-basis ease-in-out 300ms',
    [theme.breakpoints.up('md')]: {
      order: 2,
      '&.has-results': {
        flexBasis: 650,
      },
      maxWidth: '60%',
    },
  },
  background: {
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 'calc(100% + 150px)',
        height: '100%',
        background: theme.palette.background.secondary,
        borderRadius: 10,
        left: -150,
      },
    },
  },
}))

const AnimatedBox = animated(Box)

export default function App() {
  const [results, setResults] = React.useState(null)
  const classes = useStyles()

  // tracking pageview with GoogleAnalytics
  React.useEffect(() => {
    initTracking()
    trackPageview()
  }, [])

  const handleSubmitSuccess = React.useCallback(
    (values) => {
      setResults(values)
    },
    [setResults]
  )

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const isDesktopResulted = matches && results !== null

  const resizeSpring = useSpring({
    w: isDesktopResulted ? 650 : 419,
    h: isDesktopResulted ? 558 : 260,
    friction: 35,
  })

  return (
    <Box className={classes.root}>
      <Header />
      <Container>
        <Grid container className={classes.outerContainer}>
          <Grid item container xs={12} className={classes.mainContainer}>
            {!matches && (
              <Grid item container xs={12}>
                <Description />
              </Grid>
            )}
            <Grid
              item
              container
              xs={12}
              md={6}
              className={cx(classes.settingsContainer, {
                'has-results': isDesktopResulted,
              })}
            >
              <Settings onSuccess={handleSubmitSuccess} />
            </Grid>
            {matches && (
              <Grid
                item
                container
                xs={12}
                md={6}
                className={cx(classes.combinedContainer, {
                  'has-results': isDesktopResulted,
                })}
              >
                <AnimatedBox
                  className={classes.background}
                  style={{
                    height: resizeSpring.h.interpolate(
                      (val) => `${Math.ceil(val)}px`
                    ),
                    width: resizeSpring.w.interpolate(
                      (val) => `${Math.ceil(val)}px`
                    ),
                  }}
                >
                  {results && <Results results={results} />}
                  {!results && <Description />}
                </AnimatedBox>
              </Grid>
            )}
            {!matches && results && <Results results={results} />}
          </Grid>
          <Grid item container xs={12} className={classes.disclaimer}>
            <Disclaimer />
          </Grid>
        </Grid>
      </Container>
      <CookieDisclaimer />
    </Box>
  )
}
