import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Button, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { COOKIE_NAME, COOKIE_EXPIRES, GDPR_URL } from '../constants'

function AcceptButton(props) {
  return (
    <Button {...props} variant="contained" color="secondary" size="small" />
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 9999,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(2),
    display: 'flex',
    flex: '0 0 auto',
  },
  content: {
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flex: '1 0 300px',
  },
}))

export default function CookieDisclaimer() {
  const classes = useStyles()
  return (
    <CookieConsent
      disableStyles
      location="bottom"
      buttonText="Elfogadom"
      containerClasses={classes.container}
      buttonClasses={classes.button}
      contentClasses={classes.content}
      ButtonComponent={AcceptButton}
      cookieName={COOKIE_NAME}
      expires={COOKIE_EXPIRES}
    >
      Sütiket használunk az oldalunk megfelelő működésének biztosításához, és az
      oldalunk látogatottságának méréséhez. Az adatkezelési szabályzatunk{' '}
      <Link
        target="_blank"
        href={GDPR_URL}
        underline="always"
        color="secondary"
      >
        itt elérhető
      </Link>
      .
    </CookieConsent>
  )
}
